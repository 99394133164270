import React, { useState, useEffect } from "react";
import Upload from "../upload/Upload";
import "./Form.css";
import { Row, Col } from "react-grid-system";
import createClientUploaderPackage from "./createClientUploaderPackage";
import { NotificationManager } from "react-notifications";
import Loader from "./../loader/loader";
import axios from "axios";
import { host } from "./../routes";

const urlParams = new URLSearchParams(window.location.search);

const isReady = () =>
    typeof window !== "undefined" &&
    typeof window.grecaptcha !== "undefined" &&
    typeof window.grecaptcha.execute !== "undefined";

const Form = ({ clientUploader, onSuccess, formDefaults }) => {
    const [formData, setFormData] = useState({...formDefaults, contact: ["produkce"]});
    const [clientUploaderPackage, setClientUploaderPackage] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [filesAdded, setFilesAdded] = useState([]);

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const onCheckboxChange = (e) => {
        const name = e.target.name;
        const checked = e.target.checked;

        if(checked) {
            if (name !== "graweb")
                setFormData({...formData, contact: [...formData.contact.filter(c => c !== "graweb"), name]});
            else
                setFormData({...formData, contact: [name]});
        }
        else {
            setFormData({...formData, contact: formData.contact.filter(c => c !== name)});
        }
    };

    const closePrevent = (e) => {
        e.preventDefault();
        return 'Soubory se stále nahrávají, chcete stránku opravdu opustit?';
    };

    const onSave = async () => {
        if (!isReady) {
            NotificationManager.warning("Nahrávání souborů není připravené!");
        }

        const action = "homepage";
        const token = await window.grecaptcha.execute(
            "6LdKfOcUAAAAAMIAdX7pWoymtbmt569YRtrJ2taO",
            { action }
        );

        if (filesAdded.length === 0) {
            NotificationManager.warning("Žádné soubory k nahrání!");
            return;
        }

        if (
            formData.name === "" ||
            formData.mail === "" ||
            formData.phone === "" ||
            formData.description === "" ||
            formData.contact.length < 1
        ) {
            NotificationManager.warning("Špatně vyplňěný formulář!");
            return;
        }

        window.onbeforeunload = closePrevent;

        setDisabled(true);
        try {
            if (token === "") {
                throw new Error("Recaptcha token není vygenerován!");
            }

            const target = urlParams.has("target")
                ? urlParams.get("target")
                : null;

            const data = await createClientUploaderPackage(
                { ...{...formData, contact: formData.contact.join(",") }, token: token, files: filesAdded},
                target,
                clientUploader
            );
            setClientUploaderPackage(data);
        } catch (e) {
            console.log(e);
            
            NotificationManager.error("Chyba při odesílání formuláře!");
            setFormData({ ...formData, description: "" });
        }
    };

    const errorHandler = () => {
        NotificationManager.error("Chyba při odesílání formuláře!");
        window.onbeforeunload = () => {};
    };

    const successHandler = async () => {
        try {
            await axios.get(
                `${host}/api/client_files/client_uploader_package/${clientUploaderPackage.id}/success`
            );
            NotificationManager.info("Formulář byl úspěšně odeslán!");
            onSuccess({ ...formData, description: "", contact: formData.contact.join(",") });
            setFormData({ ...formData, description: "" });
            setDisabled(false);
            window.onbeforeunload = () => {};
        } catch (e) {
            errorHandler();
        }
    };

    useEffect(() => {
        if (clientUploader) {
            setDisabled(false);
        }
    }, [clientUploader]);

    return (
        <div className="form">
            <div className="form_fields">
                <Row>
                    <Col xs={12}>
                        <label htmlFor="name">Vaše jméno</label>
                        <input
                            className="desc_input"
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={onChange}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <label htmlFor="mail">Email</label>
                        <input
                            className="desc_input"
                            type="email"
                            name="mail"
                            value={formData.mail}
                            onChange={onChange}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <label htmlFor="phone">Telefon</label>
                        <input
                            className="desc_input"
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={onChange}
                        />
                    </Col>
                    <Col xs={12}>
                        <label htmlFor="description">Popis</label>
                        <input
                            className="desc_input"
                            type="text"
                            name="description"
                            value={formData.description}
                            onChange={onChange}
                        />
                    </Col>
{/*                     <Col xs={12}>
                        <p style={{marginTop: 8, marginBottom: 3}}>Příjemce</p>
                        <div>
                            <input type="checkbox" id="graweb" name="graweb" onChange={onCheckboxChange} checked={formData.contact.includes('graweb')}/>
                            <label htmlFor="graweb">Graweb</label>
                        </div>
                        <div>
                            <input type="checkbox" id="hubatka" name="hubatka" onChange={onCheckboxChange} checked={formData.contact.includes('hubatka')}/>
                            <label htmlFor="hubatka">Tisk a grafika – Lukáš Hubatka</label>
                        </div>
                        <div>
                            <input type="checkbox" id="sedivy" name="sedivy" onChange={onCheckboxChange} checked={formData.contact.includes('sedivy')}/>
                            <label htmlFor="sedivy">Tisk a grafika – František Šedivý</label>
                        </div>
                        <div>
                            <input type="checkbox" id="konecny" name="konecny" onChange={onCheckboxChange} checked={formData.contact.includes('konecny')}/>
                            <label htmlFor="konecny">Produkce – Kamil Konečný</label>
                        </div>
                    </Col> */}
                </Row>
            </div>
            <Upload
                clientUploaderPackage={clientUploaderPackage}
                onSuccess={successHandler}
                onError={errorHandler}
                filesAdded={setFilesAdded}
                disabled={disabled}
            />
            <small>
                Maximální velikost souboru je <strong>1GB</strong>.
            </small>
            <button onClick={onSave} disabled={disabled}>
                Uložit
            </button>
            <Loader display={disabled}>
                <span className="loader-title">Nahrávají se soubory</span>
                <span style={{height: 200}}></span>
            </Loader>
        </div>
    );
};

export default Form;
