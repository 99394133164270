import React, { useState, useEffect } from "react";
import Dropzone from "../dropzone/Dropzone";
import "./Upload.css";
import Progress from "../progress/Progress";
import { Row, Col } from "react-grid-system";
import { NotificationManager } from "react-notifications";
import { host } from "./../routes";
import { fixFilename } from "./fileNameMethods";
import * as tus from 'tus-js-client'

//var SparkMD5 = require("spark-md5")

const uploadData = {
    files: [],
    uploading: false,
    uploadProgress: {},
    successFullUploaded: false,
};

/* function checksum (file) {
    return new Promise((resolve, reject) => {
        var blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice,
        chunkSize = 2097152,                             // Read in chunks of 2MB
        chunks = Math.ceil(file.size / chunkSize),
        currentChunk = 0,
        spark = new SparkMD5.ArrayBuffer(),
        fileReader = new FileReader();

        fileReader.onload = function (e) {
            console.log('read chunk nr', currentChunk + 1, 'of', chunks);
            spark.append(e.target.result);                   // Append array buffer
            currentChunk++;

            if (currentChunk < chunks) {
                loadNext();
            } else {
                console.log('finished loading');
                console.info('computed hash', spark.end());  // Compute hash
                resolve(spark.end());
            }
        };

        fileReader.onerror = function () {
            console.warn('oops, something went wrong.');
            reject('oops, something went wrong.')
        };

        function loadNext() {
            var start = currentChunk * chunkSize,
                end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;

            fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
        }

        loadNext();
    })
} */

const Upload = ({
    clientUploaderPackage,
    onSuccess,
    onError,
    filesAdded,
    disabled,
}) => {
    const [state, _setState] = useState(uploadData);

    const stateRef = React.useRef(state);
    const setState = (data) => {
        stateRef.current = data;
        _setState(data);
    };

    const onFilesAdded = (files) => {
        if (disabled) {
            return;
        }
        files = files.map((file) => {
            if (file.size > 1048576000) {
                file["error"] =
                    "Soubor je příliš velký! Tento soubor nebude nahrán.";
            }
            const filename = fixFilename(file.name)
            const newFile = new File([file], filename);
            return newFile;
        });



        const new_files = state.files.concat(files);

        const filesWithoutError = [...new_files].filter(
            (f) => !f.hasOwnProperty("error")
        );
        if (filesWithoutError.length > 0) {
            filesAdded(filesWithoutError.map((file) => file.name));
        }

        setState({
            ...state,
            files: new_files,
        });
    };

    const removeFile = (e, filename) => {
        if (disabled) {
            return;
        }
        e.stopPropagation();
        const files = state.files.filter((file) => file.name !== filename);
        files.map((file) => {
            if (file.size > 1048576000) {
                file["error"] =
                    "Soubor je příliš velký! Tento soubor nebude nahrán.";
            }
            return file;
        });

        const filesWithoutError = [...files].filter(
            (f) => !f.hasOwnProperty("error")
        );
        if (filesWithoutError.length > 0) {
            filesAdded(filesWithoutError.map((file) => file.name));
        }

        setState({
            ...state,
            files: files,
        });
    };

    const uploadFiles = async () => {
        setState({ ...state, uploadProgress: {}, uploading: true });
        try {
            const up = async (files) => {
                for (const file of files) {
                    if (!file.hasOwnProperty("error")) {
                        //console.log(await checksum(file))
                        await sendRequest(file);
                    }
                }
            };
            await up(state.files);

            setState({
                ...state,
                uploading: false,
                uploadProgress: {},
                files: [],
            });
            onSuccess();
        } catch (e) {
            setState({
                ...state,
                uploading: false,
                files: [],
            });
            onError();
        }
    };

    const sendRequest = (file) => {

        return new Promise((resolve, reject) => {
            // Create a new tus upload
            var upload = new tus.Upload(file, {
                // Endpoint is the upload creation URL from your tus server
                endpoint: `${host}/files?id=${clientUploaderPackage.id}`,
                // Retry delays will enable tus-js-client to automatically retry on errors
                retryDelays: [0, 3000, 5000, 10000, 20000],
                // Attach additional meta data about the file for the server
                chunkSize: 20971520,
                metadata: {
                    filename: file.name,
                    filetype: file.type,
                },
                // Callback for errors which cannot be fixed using retries
                onError: function(error) {
                    const state = stateRef.current;
                    const copy = { ...state.uploadProgress };
                    copy[file.name] = { state: "error", percentage: 0 };
                    setState({ ...state, uploadProgress: copy });
                    NotificationManager.error(
                        `Při nahrávání ${file.name} se vyskytla chyba!`
                    );
                    reject("Not ok");
                },
                // Callback for reporting upload progress
                onProgress: function(bytesUploaded, bytesTotal) {
                    const state = stateRef.current;
                    const copy = { ...state.uploadProgress };
                    copy[file.name] = {
                        state: "pending",
                        percentage: (bytesUploaded / bytesTotal) * 100,
                    };
                    setState({
                        ...state,
                        uploading: true,
                        uploadProgress: copy,
                    });
                },
                // Callback for once the upload is completed
                onSuccess: function() {
                    const state = stateRef.current;
                    const copy = { ...state.uploadProgress };
                    copy[file.name] = { state: "done", percentage: 100 };
                    setState({
                        ...state,
                        uploadProgress: copy,
                        uploading: true,
                    });
                    NotificationManager.success(
                        `Soubor ${file.name} byl úspěšně nahrán!`
                    );
                    resolve();
                },
            });

            // Start the upload
            upload.start();
        });
    };

    useEffect(() => {
        if (clientUploaderPackage != null) {
            uploadFiles();
        }
    }, [clientUploaderPackage]);

    const renderProgress = (file) => {
        const uploadProgress = state.uploadProgress[file.name];
        if (state.uploading) {
            return (
                <div className="progress-wrapper">
                    <Progress
                        progress={
                            uploadProgress ? uploadProgress.percentage : 0
                        }
                    />
                    <img
                        className="check-icon"
                        alt="done"
                        src="baseline-check_circle_outline-24px.svg"
                        style={{
                            opacity:
                                uploadProgress &&
                                uploadProgress.state === "done"
                                    ? 0.5
                                    : 0,
                        }}
                    />
                </div>
            );
        }
    };

    return (
        <div className="upload">
            <Row>
                <Col>
                    <Dropzone
                        onFilesAdded={onFilesAdded}
                        disabled={state.uploading}
                    >
                        {state.files.length > 0 && (
                            <div className="files">
                                {state.files.map((file) => {
                                    return (
                                        <div key={file.name} className="row">
                                            <span className="filename">
                                                {file.name}
                                            </span>
                                            {!file.hasOwnProperty("error") &&
                                                renderProgress(file)}
                                            {file.hasOwnProperty("error") && (
                                                <span className="danger-text">
                                                    {file.error}
                                                </span>
                                            )}
                                            {!disabled && (
                                                <span
                                                    className="delete-file"
                                                    onClick={(e) =>
                                                        removeFile(e, file.name)
                                                    }
                                                >
                                                    Smazat
                                                </span>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </Dropzone>
                </Col>
            </Row>
        </div>
    );
};

export default Upload;
